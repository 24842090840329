*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  width: 100%;
  background: rgb(243,231,233);
  background: linear-gradient(90deg, rgba(243,231,233,1) 17%, rgba(227,238,255,1) 100%);
  overflow-x: hidden;
}
html:focus-within{
  scroll-behavior: smooth;
}
.whole{
  display: flex;
  width: 100%;
  height:auto;
}
.appear{
  animation: appear 1s;
  animation-fill-mode: forwards;
}
/*-----------------------MOBILE CSS------------------------*/
@media only screen and (max-width: 815px) {
  html {
    width: 100%;
  }
  .body{
    width: 100%;
  }
  .whole{
    width:100%;
  }
  .App{
    width: 100%;
  }
  .header{
    border-bottom: solid white 1px;
    font-family: 'Work Sans', sans-serif;
    background:white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width:100%;
    height: 40px;
    z-index: 2;
  }
  .my-logo{
    margin: 0 10px;
  }
  #logo{
    height: 20px;
    width: 20px;
  }
  .nav ul{
    display: flex;
    
  }
  .nav ul li{
    list-style: none;
    margin: 0 8px;
    transition: 0.4s ease;
    font-size: 8px;
  }
  .resume a{
    color: #56b859 !important;
  }
  .nav ul li:hover{
    cursor: pointer;
    color: rgb(54, 73, 98);
  }
  .nav a{
    text-decoration: none;
    color: rgb(163, 163, 163);
  }
  .icon:active{
    opacity: 1.0;
  }
  .align{
    margin: none;
  }
  .hero{
    height: 40vh;
  }
  .hero h2{
    margin-top: 80px;
    margin-left: 30px;;
    font-family: 'Chango', cursive;
    font-size: 2em;
  }
  .hero-flex{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #development{
    width: 300px;
    align-self: center;
  }
  
  .intro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about{
    display:flex;
    flex-direction: column;
    width: 100% !important;
  }
  .about h2{
    margin-top: 100px;
    font-size: 2.5rem;
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 30px;
    text-align: center;
  }
  .abo{
    width: 70%;
    margin: 30px 0;
  }
  .about p{
    font-family: 'Dosis', sans-serif;
    font-size: 12px;
    font-weight: 300;
    margin: 0 60px;
    width: auto;
    height: auto;
  }
  .about-section1{
    opacity: 0;
  }
  .about-section2{
    opacity: 0;
    text-align: end;
    align-self: flex-end;
  }
  .about-section3{
    opacity: 0;
  }
  .technologies{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  #section-2 h2{
    font-size: 2rem;
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 30px;
    text-align: center;
  }
  .tech-box{
    margin: 10px !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: 120px !important;
    height: 50px;
    backdrop-filter: blur(5px);
  }
  .tilt {
    border: 15px;
    width: 100px;
    background: rgb(239,239,239);
    background: linear-gradient(90deg, rgba(239,239,239,0.6) 0%, rgba(239,239,239,0.1) 100%);
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
  }
  .real-tech{
    display: flex;
    justify-content: center;
    transform: translateZ(100px);
    z-index:11;
    transform-style: preserve-3d;
    font-size: 6px !important;
  }
  .real-tech div{
    display:flex;
    align-items: center;
  }
  .tech-icon{
    margin: 0 10px;
    height: 20px;
    width: 20px;
  }
  .projects{
    position: relative;
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  .project-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .projects h1{
    font-size: 2.5rem;
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 30px;
    text-align: center;
  }
  .container{
    opacity: 0;
    margin: 20px auto !important;
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .content{
    display: flex;
    justify-content: center;
  }
  #overlay0, #overlay1, #overlay2{
    padding: 0 auto;
    display:none;
    background:cadetblue;
    opacity: 0.9;
    transition: 0.3s ease;
  }
  .content, #overlay0, #overlay1, #overlay2 {
    grid-area: 1 / 1;
    position:relative;
  }
  .proj-img{
    width: 240px;
    height: 240px;
  }
  #pointer0, #pointer1, #pointer2{
    display:none;
    top: 50%;
    left: 45%;
    position: absolute;
    width: 40px;
    height: 40px;
    color: white;
  }
  .flex{
    margin-top: 50px;
    top: 50%;
    display: flex;
    flex-direction: column;
  }
  .flex button{
    display:none;
  }
  .flex-mobile{
    display:flex;
    justify-content: space-around;
  }
  .mobile-btn{
    font-family: 'Unica One', cursive;
    font-size: 16px;
    width: 100px !important;
    margin: 8px 0;
    padding: 10px;
    border-radius: 15px;
    border: 3px solid #364962 !important;
    color:#364962 !important;
  }
  .show-item{
    display:flex !important;
    justify-content: center;
  }
  .proj-btn{
    margin: 20px auto;
    position: relative;
    width: 80px;
    height: 40px;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
  }
  .proj-btn::before, .proj-btn::after{
    border: 3px solid white;
    content: '';
    position: absolute;
    width: calc( 100% - 6px);
    height: calc( 100% - 6px);
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: transform 0.3s ease;;
  }
  .proj-btn:hover::after{
    transform:translate( -5px, -5px);
  }
  .proj-btn:hover::before{
    transform:translate( 5px, 5px);
  }
  .horizontal label{
    margin-right: 10px !important;
    width: 24px;
  }
  .contact{
    margin: 0 auto 10px auto !important;
    opacity: 0;
  }
  .footer-relative{
    position: relative;
  }
  .footer{
    height: 20px !important;
    font-size: 10px !important;
    width: 100%;
  }
  
}

/*---------------NONMOBILE APPROPRIATE---------------------*/
@media only screen and (min-width: 816px) {
  .header{
    border-bottom: solid white 1px;
    font-family: 'Work Sans', sans-serif;
    background:white;
    display: flex;
    align-items: center;
    justify-content:space-between;
    position: fixed;
    width:100%;
    height: 60px;
    z-index: 2;
  }
  .my-logo{
    margin: 0 18px;
  }
  #logo{
    height: 44px;
    width: 44px;
  }
  .nav ul{
    margin: auto 14px;
    display: flex;
  }
  .nav ul li{
    list-style: none;
    margin: 0 16px;
    transition: 0.4s ease;
    font-size: 18px;
  }
  .nav ul li:hover{
    cursor: pointer;
    color: rgb(54, 73, 98);
  }
  .nav a{
    text-decoration: none;
    color: rgb(163, 163, 163);
  }
  .resume{
    color: #F36D5E !important;
  }
  .resume:hover{
    cursor: pointer;
    color: rgb(54, 73, 98);
  }
  .whole{
    width:100%;
    height: auto;
  }
  .align{
    margin: 110px 40px 0 60px;
    
  }
  .hero{
    height: 100vh;
  }
  .hero h2{
    padding-top: 60px;
    font-family: 'Chango', cursive;
    font-size: 6em;
    text-align: center;
  }
  .hero-flex{
    margin-top:40px;
    display: flex;
    justify-content: center;
  }
  #development{
    width: 600px;
  }
  .intro{
    display:flex;
    flex-direction: column;
    align-self: center;
  }
  .about h2{
  padding-top: 40px;
  font-size: 5rem;
  font-family: 'Orbitron', sans-serif;
  text-align: center;
  }
  .abo{
    width: 100%;
    margin-bottom: 100px;
    display:flex;
  }
  .about p{
    font-family: 'Dosis', sans-serif;
    font-size: 36px;
    margin: 20px 60px;
    width: 70%;
  }
  .about-section1{
    opacity: 0;
  }
  .about-section2{
    opacity: 0;
    align-self: flex-end;
    justify-content: flex-end;
  }
  .about-section3{
    opacity: 0;
  }
  .technologies{
    margin: 0 auto;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    flex-flow: wrap;
  }
  #section-2 h2{
    padding-top:50px;
    font-size: 5rem;
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 30px;
    text-align: center;
  }
  .tech-box{
    position: relative;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    width: 400px;
    height: 150px;
    background: none;
    backdrop-filter: blur(5px);
  }
  .tilt {
    border: 15px;
    width: 300px;
    background: linear-gradient(90deg, rgba(239,239,239,0.6) 0%, rgba(239,239,239,0.1) 100%);
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
  }
  .real-tech{
    display: flex;
    justify-content: center;
    transform: translateZ(100px);
    z-index:11;
    transform-style: preserve-3d;
  }
  .tech-icon{
    margin: 0 10px;
    height: 50px;
    width: 50px;
  }
  .tech-box ion-icon{
    font-size: 10px;
  }
  .projects{
    position: relative;
    padding-top: 170px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .project-container{
    opacity:0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .projects h1{
    font-size: 5rem;
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 30px;
    text-align: center;
  }
  .proj-img{
    width: 300px;
    height: 300px;
  }
  #pointer0, #pointer1, #pointer2{
    top: 50%;
    left: 45%;
    position: absolute;
    width: 40px;
    height: 40px;
    color: white;
  }
  .flex{
    margin-top: 40px;
    top: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .flex-mobile{
    display:none;
  }
  .proj-btn{
    margin: 20px auto;
    position: relative;
    width: 80px;
    height: 40px;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
  }
  .proj-btn::before, .proj-btn::after{
    border: 3px solid white;
    content: '';
    position: absolute;
    width: calc( 100% - 6px);
    height: calc( 100% - 6px);
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: transform 0.3s ease;;
  }
  .proj-btn:hover::after{
    transform:translate( -5px, -5px);
  }
  .proj-btn:hover::before{
    transform:translate( 5px, 5px);
  }
  .show-item{
    display: flex !important;
    justify-content: center;
  }
}
/*------------HEADER/NAV SECTION----------------*/


/*---------------HERO SECTION--------------------*/

.intro h4{
  font-family: 'Source Code Pro', monospace;
  font-size: 20px;
}
.intro h1{
  font-family: 'Bebas Neue', cursive;
  font-size: 42px;
}

.hero h3 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  width:min-content;
  font-family: 'Source Code Pro', monospace;
  font-size: 1.2em;
  animation-fill-mode: forwards;
  animation: 
    typing 2.5s steps(13, end),
    blink-caret .75s step-end infinite;
}
/*------------ SOCIAL NETWORK BAR----------------*/
.socials{
  margin: 0 10px;
  position: fixed;
  bottom: 0;
  width: 40px;
  z-index: 5;
}
.social-box{
  margin: 30px 0;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  width: 34px;
  height: 34px;
}
.icon{
  margin: 0;
  color: white;
  width: 36px;
  height: 36px;
  transition: 0.4s;
}
.icon:hover{
  transform: scale(1.1);
  color:black !important;
  cursor:pointer;
}
.icon-inner{
  display: flex;
  vertical-align: middle;
  justify-content: center;
}
.actual-icon:hover{
  cursor: pointer;
  color: white;
}
.line{
  margin: 20px 0 0 16px; 
  border-left: solid grey 2px;
  width: 10px;
  height: 80px;
}

.align{
  width: 100%;
}
/*------------------TECHNOLOGIES --------------*/
.technologies::after{
  content:'';
  position: fixed;
  border:none;
  background: #00B6FF;
  clip-path: circle(40% at 50% 50%);
  top: 100px;
  left: 105px;
  width: 300px;
  height: 300px;
  z-index: -1;
}
#React:hover{
  background:#61DAFB;
}
#HTML:hover{
  background: #DD4B25;
}
#CSS:hover{
  background:#2194F0;
}
#Javascript:hover{
  background:#F1C026;
}
#WordPress:hover{
  background: #207196;
}
#PHP:hover{
  background: #858EB8;
}
#MySQL:hover{
  background: #005E86;
}
#Bootstrap:hover{
  background: #543A7B;
}
#JQuery:hover{
  background: #0865A7;
}
#MongoDB:hover{
  background: #5CA64E;
}
#Express:hover{
  background: #7E7E7E;
}
#NodeJS:hover{
  background: #83C041;
}
/*------------PROJECTS SECTION -------------*/
.projects::after{
  content:'';
  position: fixed;
  background: #56B859;
  border:none;
  clip-path: circle(40% at 50% 50%);
  bottom: 100px;
  right: 205px;
  width: 300px;
  height: 300px;
  z-index: -1;
}
.container {
  display: grid;
  flex-direction: column;
  justify-content: center;
  max-width: 340px;
  min-height: 500px;
}
.container h1{
  margin: 4px auto;
  font-family: 'Chango', cursive;
  font-size: 26px;
}
#overlay0, #overlay1, #overlay2{
  padding: 0 auto;
  display:none;
  background:cadetblue;
  opacity: 0.9;
  transition: 0.3s ease;
}
.content, #overlay0, #overlay1, #overlay2 {
  grid-area: 1 / 1;
  position:relative;
}
.container{
  margin: 20px 20px;
  padding: 20px;
  background: #C7C7C7;
  height: 100%;
  position: relative;
  background: #e0e0e0;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94, 104, 121, 0.288);
}
.container p{
  font-family: 'Unica One', cursive;
}

/*-------------------- CONTACT SECTION-------------*/
.space{
  height: 600px;
  display:flex;
  align-items: center;
}
.contact{
  opacity: 0;
  padding: 2px;
  position: relative;
  background: #e0e0e0;
  border-radius: 5px;
  margin: 30px auto 70px auto;
  width: 75%;
  max-width: 800px;
  /*background: rgba(199, 199, 199);*/
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94, 104, 121, 0.288);
}
.contact h1{
  margin: 10px 0;
  color: #364962;
  text-align: center;
  font-family: 'Leckerli One', cursive;
  font-size: 2em;
}
.contact label{
  font-family: 'Unica One', cursive;
}
.contact input, .contact textarea{
  background: none;
  padding: 3px;
  margin-right: 40px;
  border:none;
  border-bottom: 2px #ffffff73 solid;
  width: 50%;
  transition: 0.6s ease;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(94, 104, 121, 0.288);
}
.contact input:focus, .contact textarea:focus,
.contact input:valid, .contact textarea:valid{
  outline: none;
  border-bottom:cyan 2px solid;
}
.contact textarea:focus + #lineup2::after,
.contact textarea:valid + #lineup2::after{
  transform: translateX(50px);
}
.contact textarea{
  height: 120px;
  resize:none;
}
.horizontal{
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

}
.contact div label{
  color: rgb(109, 109, 109);
  margin-left: 10px;
  vertical-align: text-top;
  padding: 0 14px;
}
.contact button{
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94, 104, 121, 0.288);
  margin: 10px auto;
  border: none;
  color: rgb(109, 109, 109);
  width: 100px;
  height: 40px;
  border-radius: 10px;
  transition: 0.5s ease;
}
.contact button:hover{
  cursor: pointer;
  background: cornsilk;
}
.footer{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 40px;
  width: 100%;
  bottom:0;
  background:white;
}
.footer p{
  color: #8893A2;
  margin: 0 10px;
}
.footer-relative{
  height: auto;
  width: 100%;
  position: relative;
}
.react-icon{
  animation: spin 3s infinite linear;
}
.App{
  width: 100vw;
}
#root{
  width: 100vw;
}
.used{
  color: #56B859;
}
.summary{
  margin: 10px 0;
  font-family: 'Open Sans Condensed', sans-serif !important;
  font-size: 18px
}
/*fade in effect for most sections*/
@keyframes appear {
  from { 
    opacity: 0;
    transform: translateY(60px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}
/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}
/*React spinner*/
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}